import React from "react";
import { Box, Dialog as MuiDialog, Typography } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { IDeviceHistory } from "../../models/Devices/types";
import { HomeMiniRounded } from "@mui/icons-material";
import { format, parseISO } from "date-fns";
interface IDialog {
  open: boolean;
  history: IDeviceHistory[];
  name: string;
  handleClose: () => void;
}
function Dialog({ open, handleClose, history, name }: IDialog) {
  return (
    <MuiDialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialogContent-root": {
          padding: "16px 0px",
        },
      }}
    >
      <DialogTitle title="Teste">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <HomeMiniRounded color="primary" />
          <Typography color="primary" fontWeight={"bold"}>
            {name.toUpperCase()}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          {history.map((item, key) => (
            <Box
              sx={{
                backgroundColor:
                  key % 2 === 0 ? "white" : "rgba(0, 204, 170, 0.1)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  textTransform: "uppercase",

                  gap: "20px",
                  padding: "10px",
                }}
              >
                <Typography color="secondary.light" fontWeight="bold">
                  {item.date
                    ? format(parseISO(item.date), "dd/MM/yyyy à's' hh:mm")
                    : ""}
                </Typography>
                <Typography
                  color={item.status ? "secondary" : "red"}
                  fontWeight="bold"
                  
                >
                  {item.status ? "Ligado" : "Desligado"}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </DialogContent>
    </MuiDialog>
  );
}

export default Dialog;

import axios from "axios";
import { IError } from "../models/Error/types";
const instance = axios.create({
  baseURL:
    process.env.REACT_APP_API_MOCK ||
    process.env.REACT_APP_API_DEV ||
    process.env.REACT_APP_API_PROD,
  timeout: 3000,
  withCredentials: true,
});
// instance.defaults.headers.common = {
//   Authorization: `Bearer ${
//     JSON.parse(localStorage.getItem("user") || "{}").access_token
//   }`,
// };
instance.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    const err: IError = {
      errorMessage: error.response
        ? error.response.data.errorMessage
        : error.message,
    };
    return Promise.reject(err);
  }
);
export default instance;

import BlockWard from "./BlockWard";
import ListWard from "./ListWard";
import { IWardProps } from "./types";
function Ward({ title, showStyle = "block", data }: IWardProps) {
  const config = {
    title: title.toUpperCase(),
    data,
  };
  return showStyle === "list" ? (
    <ListWard {...config} />
  ) : (
    <BlockWard {...config} />
  );
}

export default Ward;

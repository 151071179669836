import { Box } from "@mui/material";
import React from "react";
function SidebarNav() {
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        width: "100%",
        background: "white",
        height: "80px",
        borderBottom: `1px solid ${theme.palette.divider}`,
      })}
    >
    </Box>
  );
}

export default SidebarNav;

import React from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";
import theme from "./configs/theme";
import { AuthProvider, GlobalProvider } from "./contexts";
import AppRoutes from "./Routes";
const queryClient = new QueryClient();
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <BrowserRouter>
        <AuthProvider>
          <GlobalProvider>
            <ThemeProvider theme={theme(false)}>
              <CssBaseline>
                <AppRoutes />
              </CssBaseline>
            </ThemeProvider>
          </GlobalProvider>
        </AuthProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;

import React from "react";
import { Box, Typography } from "@mui/material";
import { motion } from "framer-motion";
const variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.3,
      duration: 0.3,
    },
  },
};
function Home() {
  const AnimatedBox = motion(Box);

  return (
    <AnimatedBox initial="hidden" variants={variants} animate="visible">
      <Typography color="">Bem vindo!</Typography>
    </AnimatedBox>
  );
}

export default Home;

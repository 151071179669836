import React from "react";
import { HomeMiniRounded } from "@mui/icons-material";
import {
  Card,
  CardActions,
  CardContent,
  styled,
  Typography,
} from "@mui/material";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { Box } from "@mui/system";
import {
  IDevice,
  IDeviceHistoryConfig,
} from "../../models/Devices/types";
import { format, parseISO } from "date-fns";
import { useQuery, UseQueryResult } from "react-query";
import { IError } from "../../models/Error/types";
import useDevices from "../../services/useDevices";
import Dialog from "../Dialog/Dialog";
const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 30,
  height: 15,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "& + .MuiSwitch-track": {
      backgroundColor: "#FF4F4F",
      opacity: 1,
      border: 0,
    },
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: theme.palette.primary.main,
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 10,
    height: 10,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
interface IDeviceProps {
  deviceData: IDevice;
}
function Device({ deviceData }: IDeviceProps) {
  const { findOne } = useDevices();
  const [open, setOpen] = React.useState<boolean>(false);
  const getDevice: UseQueryResult<IDeviceHistoryConfig, IError> = useQuery(
    ["newDevice", deviceData._id],
    () => findOne(deviceData._id),
    { enabled: false }
  );
  function handleOpenModal() {
    getDevice.refetch();
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }
  if (getDevice.isSuccess) {
    console.log("From Device", getDevice.data);
  }
  return (
    <>
      <Dialog
        open={open}
        name={deviceData.bed}
        handleClose={handleClose}
        history={getDevice.isSuccess ? getDevice.data.data : []}
      />
      <Card
        sx={{
          maxWidth: "300px",
          "&:hover": {
            boxShadow: 3,
            cursor: "pointer",
          },
        }}
        onClick={handleOpenModal}
      >
        <CardActions>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              margin: "0px 5px",
              alignItems: "center",
            }}
          >
            <HomeMiniRounded color="secondary" />
            <IOSSwitch checked={deviceData.status} />
          </Box>
        </CardActions>
        <CardContent>
          {/* <Typography variant="inherit" color={`secondary`} component="span">
          <HotelRounded />
        </Typography> */}
          <Typography fontWeight={700} color="secondary" fontSize={"2em"}>
            {deviceData.bed.toUpperCase()}
          </Typography>
        </CardContent>
        <CardActions
          sx={{
            backgroundColor: "rgba(0, 204, 170, 0.05)",
          }}
        >
          <Typography fontWeight={"bold"} fontSize={".6em"} color={"secondary"}>
            ÚLTIMA AÇÃO :{" "}
            <Typography variant="inherit" color="primary" component={"span"}>
              {format(parseISO(deviceData.date), "dd/MM/yyyy")}
            </Typography>{" "}
            ÀS{" "}
            <Typography variant="inherit" color="primary" component={"span"}>
              {format(parseISO(deviceData.date), "hh:mm")}
            </Typography>
          </Typography>
        </CardActions>
      </Card>
    </>
  );
}

export default Device;

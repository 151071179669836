import { LogoutRounded, Notifications } from "@mui/icons-material";
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import React, { useContext } from "react";
import { AuthContext } from "../../contexts";
import { AnimatedOxygenLogo } from "../../helpers";
function SidebarUserProfile() {
  const { name, logout, background_color} = useContext(AuthContext);
  return (
    <Box
      sx={(theme) => ({
        width: "100%",
        height: "120px",
        backgroundColor: theme.palette.primary.main,
        padding: "20px",
      })}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          position: "relative",
        }}
      >
        <AnimatedOxygenLogo fill="white" split={1.5} />
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
          }}
        >
          <IconButton sx={{ background: "rgba(0, 0, 0, 0.13)" }}>
            <Notifications
              sx={{
                color: "white",
                width: "20px",
                height: "20px",
              }}
            />
          </IconButton>
          <IconButton sx={{ background: "rgba(0, 0, 0, 0.13)" }} onClick={logout}>
            <LogoutRounded
              sx={{
                color: "white",
                width: "20px",
                height: "20px",
                transform: "rotate(180deg)",
              }}
            />
          </IconButton>
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: 65,
            left: 0,
            right: 0,
            marginRight: "auto",
            marginLeft: "auto",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar
            sx={{
              fontSize: ".9em",
              background: background_color ?? "tomato",
              display: "flex",
              flexDirection: "column",
              width: "65px",
              height: "65px",
            }}
          >
            {name?.charAt(0) +""+ name?.split(".")[1].charAt(0)}
          </Avatar>
          <Typography
            textAlign={"center"}
            fontSize={""}
            mt={1}
            fontFamily={"nunito"}
            fontWeight={700}
            color={"secondary"}
          >
            Olá,{" "}
            <Typography variant="inherit" color="primary" component={"span"}>
              {name?.replace(".", " ")}
            </Typography>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default SidebarUserProfile;

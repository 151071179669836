import React from "react";
import { Box, List, Divider, Drawer } from "@mui/material";
import SidebarContent from "./SidebarContent";
import SidebarNav from "./SidebarNav";
import SidebarSubNav from "./SidebarSubNav";
import SidebarUserProfile from "./SidebarUserProfile";
import { items } from "./Data/sidebarData";
import ListItemLink from "../ListItemLink/ListItemLink";
import { AuthContext } from "../../contexts";
import { useLocation } from "react-router-dom";
import SidebarDevices from "./SidebarDevices";
const navWidth = 280;
interface SidebarProps {}
function Sidebar(props: SidebarProps) {
  const { access_token } = React.useContext(AuthContext);
  const location = useLocation();
  return (
    <>
      {access_token ? (
        <Box sx={{ display: "flex" }}>
          <Drawer
            sx={(theme) => ({
              height: "100vh",
              display: "flex",
              width: navWidth,
              flexDirection: "column",
              background: "white",
              borderRight: `1px solid ${theme.palette.divider}`,
              "& .MuiPaper-root": {
                width: navWidth,
              },
            })}
            open={true}
            variant="persistent"
          >
            <SidebarUserProfile />
            <Box mt={9}>
              <List>
                <Divider variant="middle" />
                {items.map((item) => (
                  <ListItemLink
                    key={item.name}
                    name={item.name}
                    to={item.to}
                    icon={item.icon(
                      location.pathname === item.to ? true : false
                    )}
                  />
                ))}
              </List>
              <SidebarDevices />
            </Box>
          </Drawer>
          <Box sx={{ width: "100%" }}>
            <SidebarNav />
            <SidebarSubNav />
            <SidebarContent />
          </Box>
        </Box>
      ) : (
        <SidebarContent />
      )}
    </>
  );
}

export default Sidebar;

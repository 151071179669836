import React, { createContext, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import instance from "../configs/api";
import { useLocalStorage } from "../hooks";
import {
  IAuthProvider,
  IAuthContext,
  IUser,
  IUserForm,
} from "../models/Auth/types";
import useAuth from "../services/useAuth";
export const AuthContext = createContext<IAuthContext>({} as IAuthContext);
export const AuthProvider = ({ children }: IAuthProvider) => {
  const { loginMutation } = useAuth();
  const [user, setUser] = React.useState<IUser | null>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const [error, setError] = React.useState<boolean>(false);
  const { set, get, remove } = useLocalStorage<IUser>();
  const navigate = useNavigate();
  const location = useLocation();
  const ref = useRef<any>();
  ref.current = () => {
    const userLocal: IUser = get("user");
    if (Object.keys(userLocal).length) {
      console.log(userLocal);
      setUser(userLocal);
      instance.defaults.headers.common = {
        Authorization: `bearer ${userLocal.access_token}`,
        company_id: userLocal.company_id ? userLocal.company_id : "",
      };
      navigate("/", { replace: true, state: { from: location } });
    }
  };
  React.useEffect(() => {
    ref.current();
  }, []);
  async function authenticate(values: IUserForm) {
    setLoading(true);
    loginMutation.mutate(values, {
      onSuccess: (response) => {
        set("user", response);
        setUser(response);
        instance.defaults.headers.common = {
          Authorization: `bearer ${response.access_token}`,
          company_id: response.company_id ? response.company_id : "",
        };
        navigate("/", { replace: true, state: { from: location } });
      },
      onError: (error) => {
        setError(true);
        setErrorMessage(error.errorMessage);
      },
      onSettled: () => {
        setLoading(false);
      },
    });
  }
  async function logout() {
    remove("user");
    setUser({});
    navigate("/login", { replace: true });
  }
  return (
    <AuthContext.Provider
      value={{
        ...user,
        loading,
        errorMessage,
        error,
        setError,
        authenticate,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

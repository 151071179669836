import React from "react";
import { Grid, Typography } from "@mui/material";
import { IWardBlockProps } from "./types";
import { grey } from "@mui/material/colors";
import Device from "../Device/Device";
function BlockWard({ title, data }: IWardBlockProps) {
  return (
    <Grid
      item
      xs={12}
      container
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: grey[50],
        padding: "10px",
        borderRadius: "5px",
        border: `1px solid ${grey[200]}`,
        gap: "10px",
      }}
    >
      <Typography
        fontFamily={"Nunito"}
        fontWeight={"bold"}
        color="primary"
        fontSize={".825em"}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        {/* <Typography variant="inherit" color={`secondary`} component="span">
          <HotelRounded />
        </Typography> */}
        {title}
      </Typography>
      <Grid
        item
        xs={12}
        sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}
      >
        {data.map((item) => (
          <Device deviceData={item} />
        ))}
      </Grid>
    </Grid>
  );
}

export default BlockWard;

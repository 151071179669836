import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
function SidebarContent() {
  return (
    <Box sx={{ width: "100%" }}>
      <Outlet />
    </Box>
  );
}

export default SidebarContent;

import React from "react";
import LoginForm from "./LoginForm";
import { Grid, Typography, useTheme } from "@mui/material";
import IotticSvg from "../../assets/svgs/desktop_login_water_mark_logo.svg";
import { Box } from "@mui/system";
import { AnimatedOxygenLogo } from "../../helpers";
import { motion } from "framer-motion";
function Login() {
  const theme = useTheme();
  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 1 } },
  };
  const AnimatedGrid = motion(Grid);
  return (
    <Grid container>
      <AnimatedGrid
        item
        variants={variants}
        initial={"hidden"}
        animate={"visible"}
        xs={12}
        md={6}
        sx={{
          backgroundRepeat: "repeat",
          height: "100vh",
          padding: "40px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "space-between", md: "space-between" },
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            width: "100%",
            flexWrap: "wrap",
            gap: theme.spacing(2),
          }}
        >
          <AnimatedOxygenLogo />
          <Typography color={"common.black"}>
            Não tem uma conta ?{" "}
            <Typography
              color={theme.palette.primary.main}
              component={"span"}
              fontWeight={600}
              sx={{
                transition: ".3s",
                "&:hover": {
                  color: theme.palette.primary.dark,
                  cursor: "pointer",
                },
              }}
            >
              Solicitar Acesso
            </Typography>
          </Typography>
        </Box>
        <Box
          mb="30px"
          textAlign={"center"}
          color="secondary.main"
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: { xs: "80px", xl: "160px" },
          }}
        >
          <Typography
            fontSize={"2.4rem"}
            fontFamily={"Rubik"}
            fontWeight={"Bold"}
          >
            Bem Vindo ao{" "}
            <Typography
              component={"span"}
              color={"primary"}
              variant="inherit"
              sx={{ position: "relative" }}
            >
              Iottic
              <Typography
                component={"span"}
                position={"absolute"}
                left="-6px"
                top={-14}
              >
                <AnimatedOxygenLogo split={2} />
              </Typography>
            </Typography>
          </Typography>
          <Typography fontFamily="Nunito" color={theme.palette.common.black}>
            Uma solução IOT para os setores de saúde.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <LoginForm />
        </Box>
      </AnimatedGrid>
      <Grid
        item
        md={6}
        sx={{
          backgroundImage: `url(${IotticSvg})`,
          backgroundColor: (theme) => theme.palette.primary.main,
          backgroundRepeat: "no-repeat",
          backgroundSize: "110%",
          backgroundPosition: "center",
          height: "100vh",
        }}
        display={{ xs: "none", md: "flex" }}
      ></Grid>
    </Grid>
  );
}

export default Login;

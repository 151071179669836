import { Grid } from "@mui/material";
import React from "react";
import { Ward } from "../../components";
import useDevices from "../../services/useDevices";
function Devices() {
  const { getMonitoringDevice } = useDevices();
  return (
    <Grid
      container
      gap={2}
      padding={"20px"}
      sx={{
        overflowY: "auto",
      }}
    >
      {getMonitoringDevice.isSuccess &&
        getMonitoringDevice.data.data.map((item) => (
          <Ward title={item.unity} data={item.results} key={item._id}/>
        ))}
    </Grid>
  );
}

export default Devices;

import { useQuery, UseQueryResult } from "react-query";
import instance from "../configs/api";
import { ITotal } from "../models/Devices/types";
import { IError } from "../models/Error/types";

function useDevices() {
  const getMonitoringDevice: UseQueryResult<ITotal, IError> = useQuery(
    "device",
    async () => {
      return instance.get("/monitoring/getAllByClient").then((res) => res.data);
    },
    {
      refetchInterval: 3000,
    }
  );

  const findOne = (id: string) => {
    return instance.get(`/monitoring/get/${id}`).then((res) => res.data);
  };
  return {
    findOne,
    getMonitoringDevice,
  };
}

export default useDevices;

import { Box, Typography } from "@mui/material";
import React from "react";
import { IWardListProps } from "./types";
function ListWard({ title, data }: IWardListProps) {
  return (
    <Box>
      <Typography>{title} - List</Typography>
    </Box>
  );
}
export default ListWard;

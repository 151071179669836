import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { Box, Typography } from "@mui/material";
import { HomeMiniRounded } from "@mui/icons-material";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 11,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
}));

function SidebarDevices() {
  return (
    <Box sx={{ margin: "10px 12px", display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
          alignItems: "center",
        }}
      >
        <Typography
          fontFamily={"Nunito"}
          fontWeight="700"
          color="secondary.main"
          fontSize=".8em"
        >
          Dispositivos{" "}
          <Typography component="span" variant="inherit" color="primary">
            Ligados
          </Typography>
        </Typography>
        <HomeMiniRounded color="secondary" />
      </Box>
      <BorderLinearProgress variant="determinate" value={0} />
      <Box sx={{ display: "flex" , justifyContent:"flex-end", marginTop:"10px"}}>
        <Typography fontFamily={"Nunito"} fontWeight={"Bold"} fontSize=".8em" color="secondary">
          <Typography variant="inherit" component={"span"} color="common.black">
            0
          </Typography>
          /30
        </Typography>
      </Box>
    </Box>
  );
}

export default SidebarDevices;
